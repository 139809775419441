// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_header__AmuiJ {\r\n\tpadding: 0px;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tposition: sticky;\r\n\ttop: 0;\r\n\twidth: 100%;\r\n\tz-index: 1000;\r\n\tbackground: linear-gradient(to right, #081b30, #222d38);\r\n}\r\n/* @media only screen and (max-width: 774px) {\r\n\t.header {\r\n\t\theight: auto;\r\n\t}\r\n} */\r\n", "",{"version":3,"sources":["webpack://./src/assemblies/layout/layout.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,gBAAgB;CAChB,MAAM;CACN,WAAW;CACX,aAAa;CACb,uDAAuD;AACxD;AACA;;;;GAIG","sourcesContent":[".header {\r\n\tpadding: 0px;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\tposition: sticky;\r\n\ttop: 0;\r\n\twidth: 100%;\r\n\tz-index: 1000;\r\n\tbackground: linear-gradient(to right, #081b30, #222d38);\r\n}\r\n/* @media only screen and (max-width: 774px) {\r\n\t.header {\r\n\t\theight: auto;\r\n\t}\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "layout_header__AmuiJ"
};
export default ___CSS_LOADER_EXPORT___;
